/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Icon/logo.png";
import facebook from "../../../Assets/Icon/facebook.svg";
import twitter from '../../../Assets/Icon/twitter.svg'
import instagram from '../../../Assets/Icon/instagram.svg'
import youtube from '../../../Assets/Icon/youtube.svg'
import linkedin from '../../../Assets/Icon/linkedin.svg'


import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg={4} sm={6} xs={12}>
            <div className="footer_in">
              <div className="logo">
                <img src={logo} alt="footer-logo" />
              </div>
              <p>
                Black Tie Asset Hub is at the forefront of transforming the real
                estate industry through the innovative use of blockchain
                technology.
              </p>
            </div>
          </Col>
          <Col lg={4} sm={6} xs={12} className="security">
            <ul className="footer-links">
              <li>
                <h6>Security</h6>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="address">
                <h6>Address</h6>
                <p>L32, 101 Miller St North Sydney NSW 2060</p>
                <p className="add_link">Phone: <Link to="tel:+ 612 94991120"> + 612 9499 1120 </Link></p>
                <p className="add_link">Support: <Link to="mailto:admin@blacktie.digital">admin@blacktie.digital</Link></p>
              </li>
            </ul>
          </Col>
          <Col lg={4} sm={6} xs={12} className="exchnge_link">
            <ul className="footer-links">
              <li>
                <h6>Independent Remittance Dealer</h6>
                <a>IND100778824-001</a>
              </li>
              <li>
                <h6>Exchange Registered Provider</h6>
                <a>DCE100778824-001</a>
              </li>
            </ul>
          </Col>

        </Row>
      </Container>
      <div className="copyright">
        <Container>
          <p>
            © {new Date().getFullYear()}. All Rights Reserved Black Tie Holdings
            Pty Ltd
          </p>
          <ul className="social-links">
              <li>
                <a
                  href="https://www.facebook.com/b4realofficial/"
                  target="_blank"
                >
                  <img src={facebook} alt="facebook-icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/b4realofficial/?originalSubdomain=au"
                  target="_blank"
                >
                  <img src={linkedin} alt="telegram-icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/i/flow/login?redirect_after_login=%2Fb4real_official"
                  target="_blank"
                >
                  <img src={twitter} alt="whatsapp-icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/blacktie_digital/#"
                  target="_blank"
                >
                  <img src={instagram} alt="instagram-icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/c/BlackTieDigitalOfficial"
                  target="_blank"
                >
                  <img src={youtube} alt="youtube-icon" />
                </a>
              </li>
            </ul>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
