export const VALIDATE = {
  EMAIL: {
    REGEX: /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    MESSAGE: "Please enter valid email",
  },
  EMAIL_PLUS: {
    REGEX: /^[^+]*$/,
    MESSAGE: "Enter valid email. '+' is not allowed.",
  },
  SCRIPT_TAG: {
    REGEX: /^[^<>]*$/,
    MESSAGE: "Script tags are not allowed.",
  },
  PASSWORD: {
    REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
    MESSAGE:
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
  },
  TLD: {
    REGEX:
      /\.(com|net|org|edu|gov|mil|in|co|info|xyz|biz|name|mobi|aero|jobs|museum|travel|asia|cat|coop|int|pro|tel|xxx|id|io|tech|me|us|uk|ca|de|fr|au|eu|cn|ru|ch|se|no|fi|es|it|jp|kr|mx|br|za|ar|cl|nz|tr|my|sg|hk|tw|th|vn|lk|np|pk|ir|sa|ae|eg|il|jo|qa|kw|om|bh|lb|az|ge|am|kz|uz|tj|tm|kg|mn|af|sy|iq|dz|ma|tn|ly|sd|ye|om|so|er|dj|et|ug|tz|ke|rw|bi|mw|zm|zw|ao|cg|cd|digital)$/,
    MESSAGE:
      "Email must end with a valid TLDs (e.g., .com, .net, .org, .edu, etc.)",
  },
  USER_NAME: {
    REGEX: /^(?=.*[A-Za-z])[A-Za-z_]{3,50}$/,
    MESSAGE: "Please enter a valid name",
  },
  LAST_NAME: {
    REGEX: /^(?=.*[A-Za-z])[A-Za-z_]{3,50}$/,
    MESSAGE: "Please ennter a valid name",
  },
  PHONE_NUMBER: {
    REGEX: /^(?!00)\d+$/,
    MESSAGE: "Invalid phone number",
  },
  COUNTRY_NAME: {
    REGEX: /^[A-Za-z\u00C0-\u017F\s'-]{1,40}$/,
    MESSAGE: "Enter a valid country name",
  },
  WHITE_SPACE: {
    REGEX: /^\S.*\S$|^\S$/,
    MESSAGE: "Space is not allowed at start or end places",
  },
};

export const COMMON_MSG = {
  REQUIRED: "Please complete this required field.",
  INVALID_DATE: "Please enter valid date format",
};

export const isEncrypted = false;
export const API_HOST =
  // "http://10.10.1.26:3000/api/v1/"
  "https://stage-api.btassethub.digital/api/v1"  // stage
  // "https://api.btassethub.digital/api/v1"; // prod
// "http://54.251.221.151:3000/api/v1/"
export const LENGTH = 128;
export const UNIQUE_REFFER_CODE = "yg12ih";
